<template>
    <div class="default-body">
        <keep-alive>
            <div class="user-info-link" style="margin-top: 1px">
                <el-row>
                    <span>选择标签查询用户</span>
                </el-row>
                <el-row>
                    <div class="group_item" v-for="item in tagGroupList">
                        <span class="group_item_name">{{item.groupName}}</span>
                        <span class="group_item_content">

                        <el-checkbox class="group_item_cell" v-for="tagItem in item.tagList" v-model="tagItem.checked"
                                     :label="tagItem.tagName" :key="tagItem.tagId"
                        >
                                {{tagItem.tagName}}
                            </el-checkbox>

                    </span>
                    </div>
                </el-row>
                <el-row>
                    <el-col :span="2" :offset="20">
                        <el-button type="primary" @click="getList()" size="mini" class="header-btn">查询</el-button>
                    </el-col>
                </el-row>
                <div>
                    <tablePagination :total="totalRecord" :pageSize="formInline.pageSize"
                                     @handlePage="handleCurrentChange">
                        <el-table border :data="userTagList" size="mini">
                            <el-table-column type="index" label="序号" width="50"></el-table-column>
                            <el-table-column prop="name" label="用户昵称" width="200"></el-table-column>
                            <el-table-column prop="avatar" label="头像" width="135">
                                <template slot-scope="scope">
                                    <el-avatar size="small" :src="scope.row.avatar"></el-avatar>
                                </template>
                            </el-table-column>
                            <!--<el-table-column prop="typeName" label="联系人类型" width="120"></el-table-column>-->
                            <el-table-column prop="genderName" label="性别" width="60"></el-table-column>
                            <el-table-column prop="remark" label="备注" width="80"></el-table-column>
                            <el-table-column prop="description" label="描述" width="60"></el-table-column>
                            <el-table-column prop="saveSuccName" label="状态"width="80"></el-table-column>
                            <el-table-column prop="tagNameList" label="标签名称">
                            </el-table-column>
                            <!--<el-table-column prop="qyName" label="服务人员"></el-table-column>-->
                            <el-table-column prop="tagNameList" label="操作" width="120">
                                <template slot-scope="scope">
                                    <el-button type="success" size="mini" @click="onChat(scope.row)"  class="header-btn">发消息</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </tablePagination>
                </div>
                <div>
                    <el-dialog
                            title="标签详情"
                            :visible.sync="drawerShow"
                            fullscreen>
                        <el-table border :data="tagList" size="mini">
                            <el-table-column type="index" label="序号" width="50"></el-table-column>
                            <el-table-column prop="tagGroupName" label="标签组" width="80"></el-table-column>
                            <el-table-column prop="tagName" label="标签" width="80"></el-table-column>
                            <el-table-column prop="tagType" label="状态" width="80">
                                <template slot-scope="scope">
                                    {{scope.row.tagType == 1 ? '企业标签' : '个人标签'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="状态" width="120"></el-table-column>
                        </el-table>
                    </el-dialog>
                </div>
            </div>
        </keep-alive>
        <!--         </defaultSec>-->
    </div>
</template>
<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    import ElRow from "element-ui/packages/row/src/row";
    import {wxQw} from "@/api/wxQwConfig";
    let wx = window.wx
    let qwPlatform = navigator.userAgent.indexOf('wxwork')>-1?true:false
    export default {
        data() {
            return {
                tagGroupList: [],
                formInline: {
                    currentPage: 1,
                    pageSize: 15,
                    qyWeixinUserid: '',
                    externalUserid: '',
                    customerId:'',
                    tagList: []
                },
                userTagList: [],
                tagList: [],
                drawerShow: false,
                totalRecord: 0
            }
        },
        components: {
            ElRow, defaultSec,
            tablePagination
        },
        mounted(){
            this.getTagGroupList();
            //企微打开:config
            wxQw()
        },
        methods: {
            getList() {
                if(window.localStorage.getItem('userId')==null||window.localStorage.getItem('userId')==''){
                    let returnPage = encodeURIComponent(window.location.href)
                    window.location.href = `/login?auth=0&returnPage=${returnPage}`

                    // this.$router.push({path:'/login',query:{auth:'0'}})
                    return
                }
                this.loading = true;
                this.formInline.tagList=[]
                for (var i = 0; i < this.tagGroupList.length; i++) {
                    for (var j = 0; j < this.tagGroupList[i].tagList.length; j++) {
                        if(this.tagGroupList[i].tagList[j].checked){
                            this.formInline.tagList.push(this.tagGroupList[i].tagList[j].tagId)
                        }
                    }
                }
                this.$apiGet('lechun-cms/tag/getCustomerQiWeiTagVoList', {item: encodeURIComponent(JSON.stringify(this.formInline)),userId:window.localStorage.getItem("userId")}).then(res => {
                    this.loading = false;
                    this.userTagList = res.list;
                    this.totalRecord = res.total;
                })
            },
            getTagGroupList(){
                this.loading = true;
                this.$apiGet('lechun-cms/tag/getTagGroupVoList', {}).then(res => {
                    this.loading = false;
                    this.tagGroupList = res;
                    this.getList();
                })
            },
            detailHandle(row){
                this.tagList = row.customerTagList
                this.drawerShow = true;
            },
            handleCurrentChange(val) {
                this.formInline.currentPage = val;
                this.getList();
            },
            onChat(row){
                if(!qwPlatform){
                    this.$message({
                        message: '浏览器不支持打开企微对话,请在企业微信打开',
                        type: 'warning'
                    });
                    return;
                }
                wx.openEnterpriseChat({
                    // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
                    externalUserIds: row.externalUserid, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
                    groupName: '',  // 会话名称。单聊时该参数传入空字符串""即可。
                    chatId: "", // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
                    success: function(res) {
                        console.log(res)
                    },
                    fail: function(res) {
                        if(res.errMsg.indexOf('function not exist') > -1){
                            alert('版本过低请升级')
                        }
                    }
                });
            },
            getConfigInfo()    {
                let appSignature; // 通过接口，获取agentConfig的参数
                const urlNow = encodeURIComponent(window.location.href);
                console.log('当前授权URL：', urlNow);
                const noncestr = Math.floor(Math.random() * 100000000000000);
                let body = {
                    url: urlNow,
                    timestamp: 1000,
                    nonceStr: noncestr,
                    type: "agent_config"
                };
                let that = this;
                this.$apiGet('lechun-cms/distributor/getQwConfig', body).then(res => {
                    appSignature = res;
                    console.log(appSignature);
                    console.log('wx.agentConfig:BEGIN');
                    wx.agentConfig({
                        corpid: appSignature.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                        agentid: appSignature.agentid, // 必填，企业微信的应用id （e.g. 1000247）生产环境
                        timestamp: appSignature.timestamp, // 必填，生成签名的时间戳
                        nonceStr: appSignature.nonceStr, // 必填，生成签名的随机串
                        signature: appSignature.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
                        jsApiList: [
                            'openUserProfile',
                            'previewFile',
                            'getCurExternalContact',
                            'openEnterpriseChat'
                        ], // 必填
                        debug:1,
                        success(res) {
                            console.log(res)
                        },
                        fail(res) {
                            console.log('err', res);
                            if (res.errMsg.indexOf('function not exist') > -1) {
                                alert('版本过低请升级');
                            }
                        }
                    });
                })
            }
        }
    }
</script>

<style scoped lang="scss" rel="stylesheet/scss">
    .el-form-item__content {
        font-size: 12px;
    }

    .el-form-item__label {
        color: #795da3;
        font-size: 13px;
    }

    .el-form-item--mini.el-form-item {
        margin-bottom: 8px;
    }

    .el-input {
        width: 99%;
    }

    .group_item {
        padding: 20px 10px 10px;
        border-bottom: 1px solid #E4E6E9;
        position: relative;
    }

    .group_item_name {
        font-size: 14px;
        width: 80px;
        padding-right: 10px;
        display: inline-block;
        vertical-align: top;
        word-break: break-word;
    }

    .group_item_content {
        width: 670px;
        display: inline-block;
    }

    .default-body {
        margin: 2px 2px 0;
        padding: 2px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 2px;
        height: 100%;
        overflow: auto;

    }
</style>